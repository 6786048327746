import { defer } from "react-router";

import { LoanApplicationService } from "../../services/LoanApplication";
import { CustomLoaderFunctionArgs } from "../../shared/router/RouteLoader";
import { GetWorkflowResponse } from "../WorkflowTimeline/interface";
import { LoanDeviation } from "./interface";

export default async function ({
  params,
  getToken,
  pageName,
}: CustomLoaderFunctionArgs) {
  const { loanID, sourceEntityID = "" } = params;
  
  return defer({
    response: await LoanApplicationService.getWorkflows({
      pageName,
      token: getToken(),
      loanApplicationID: loanID as string,
    }),
  });
}


