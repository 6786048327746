import { useEffect, useState } from "react";
import {
  Outlet,
  useLoaderData,
  useNavigate,
  useParams,
} from "react-router-dom";
import { ArrowDownTrayIcon } from "@heroicons/react/20/solid";
import { Get, Post } from "../../shared/services/Fetch";
import { notify } from "../../shared/context/AlertContext";
import {
  BackwardIcon,
  ArrowPathIcon,
  DocumentCheckIcon,
} from "@heroicons/react/24/outline";
import { Bars3BottomRightIcon } from "@heroicons/react/24/outline";

import { CommonService } from "../../services/Common";
import { TabsOne } from "../../components/atomic/Tabs";
import { downloadLink } from "../../shared/utils/DateUtil";
import GetAuthContext from "../../shared/context/AuthContext";
import { LOAN_STATUS_CONFIG } from "../../shared/utils/Common";
import DialogOne from "../../components/atomic/Dialog/DialogOne";
import PageHeader from "../../components/atomic/Header/PageHeader";
import { loanDetailFields, loanDetailSidebarFields } from "./constant";
import { LoanApplicationService } from "../../services/LoanApplication";
import ApproveKYCModal from "../../components/composed/ApproveKYCModal";
import { BreadCrumb } from "../../components/atomic/Breadcrumbs/interface";
import { CustomLoaderFunctionArgs } from "../../shared/router/RouteLoader";
import GenerateLinkModal from "../../components/composed/GenerateLinkModal";
import DetailsSidebar from "../../components/composed/DetailsSidebar/DetailsSidebar";
import CancelLoanApplicationModal from "../../components/composed/CancelLoanApplicationModal";
import DisqualifyLoanApplicationModal from "../../components/composed/DisqualifyLoanApplicationModal";
import {
  noHeadersResponse,
  getTabsByProductType,
} from "../../shared/utils/Global";

import SliderContainer from "../LoanTermDeviation/SliderContainer";
import ConfirmationModal from "../../components/composed/ConfirmationModal";
import ActionButton from "../../components/composed/ActionButton";
import GetMixpanelContext from "../../shared/context/MixpanelContext";
import { ACTIONS_LIST, ORG_IDS } from "../../shared/utils/constants";
import PrivateComponent from "../../components/composed/PrivateComponent";
import SendSignAgreementModal from "../../components/composed/SendSignAgreementModal";

import RejectLoanIcon from "../../assets/icons/reject_loan.svg";
import ApproveLoanIcon from "../../assets/icons/approve_loan.svg";
import CancelApplicationIcon from "../../assets/icons/cancel_application.svg";
import { CollapsibleSidebar } from "../../components/atomic/CollapsibleSidebar";
import Notes from "./components/Notes";

interface LoaderReturnArgs<T> {
  data: T;
  status: boolean;
  error: string;
}

export interface LoanApplicationDetailsContext {
  reloadLoanDetails: Function;
  loanDetails: any;
  hasMorePages: boolean;
  workflowLoaderData: any;
  reloadWorkflowDetails: Function;
  selectedCoApplicantUserID: any;
  setSelectedCoApplicantUserID: any;
}

export async function _getNotes(
  loanID: string,
  token: string,
  pageName: string
) {
  const headers = new Headers();
  if (pageName) {
    headers.append("page-header", pageName);
  } else {
    return noHeadersResponse();
  }
  return await Get(
    `/masterDashboard/notes?loanApplicationID=${loanID}`,
    token,
    headers
  );
}

export async function loanApplicationDetailsLoader({
  params,
  getToken,
  pageName,
}: CustomLoaderFunctionArgs) {
  const token = getToken();
  const { loanID, sourceEntityID = "" } = params;

  const promises = await Promise.all([
    await LoanApplicationService.getWaitState({
      token,
      loanApplicationID: loanID as string,
      pageName,
    }),
    await LoanApplicationService.getLoanDetails({
      loanApplicationID: loanID as string,
      token,
      pageName,
    }),
    await LoanApplicationService.getNotes({
      loanApplicationID: loanID as string,
      token,
      pageName,
    }),
    await LoanApplicationService.getLoanOffer({
      loanApplicationID: loanID as string,
      token,
      pageName,
    }),
    await LoanApplicationService.getOfferNegotiationWFStatus({
      loanApplicationID: loanID as string,
      token,
      pageName,
    }),
    await LoanApplicationService.getWorkflows({
      loanApplicationID: loanID as string,
      token,
      pageName,
    }),
  ]);

  return await [...promises, _getNotes];
}

function LoanApplicationDetails({ pageName }: any) {
  const { sourceEntityID, loanID } = useParams();
  const { setCurrentScreen, trackUserAction } = GetMixpanelContext();
  const [
    { data: waitStateLoaderData },
    { data: loanDetailsLoaderData, status, error },
    { data: notesLoaderData },
    { data: loanOfferDetails },
    { data: loanTermDeviationStatus },
    { data: workflowLoaderData },
    _getNotes,
  ] = useLoaderData() as LoaderReturnArgs<any>;

  const [waitStateData, setWaitStateData] = useState(
    waitStateLoaderData ?? null
  );
  const [loanDetailsData, setLoanDetailsData] = useState(loanDetailsLoaderData);
  const [waitLoading, setWaitLoading] = useState(false);
  const [notesData, setNotesData] = useState(notesLoaderData);
  const [isGenerateLinkModalOpen, setIsGenerateLinkModalOpen] =
    useState<boolean>(false);
  const [webLink, setWebLink] = useState<string>("");
  const [notes, setNotes] = useState<any[]>(notesData?.notes ?? []);
  const [showApproveKYCModal, setShowApproveKYCModal] =
    useState<boolean>(false);
  const [showDisqualifyLoanModal, setShowDisqualifyLoanModal] =
    useState<boolean>(false);
  const [showCancelLoanApplicationModal, setShowCancelLoanApplicationModal] =
    useState<boolean>(false);

  const [isNotesOpen, setIsNotesOpen] = useState<boolean>(false);

  const [bcRange, setBcRange] = useState(null);
  const [bcStatus, setBcStatus] = useState(null);
  const [showBankConnect, setShowBankConnect] = useState(false);
  const [showSendSignAgreementModal, setShowSendSignAgreementModal] =
    useState(false);
  const { fetchToken, userState, getPageConfig } = GetAuthContext();
  const { permissions } = userState;
  const pageConfig = getPageConfig();
  const isMobile = window.innerWidth < 640;
  const { loanDetails = {} } = loanDetailsData ?? {};
  const [workflowData, setWorkflowData] = useState(workflowLoaderData);

  const [isSliderOpen, setIsSliderOpen] = useState<boolean>(false);
  const [currentActiveSlider, setCurrentActiveSlider] = useState<string>("");
  const [sliderBack, setSliderBack] = useState<boolean>(false);
  const [timelineInfo, setTimelineInfo] = useState<any>();
  const [refetchLoanTerm, setRefetchLoanTerm] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const navigate = useNavigate();

  const currentActiveSliderHandler = (
    slider: string,
    nested = false,
    info: any = {}
  ) => {
    if (!isSliderOpen) {
      setIsSliderOpen(true);
    }
    setCurrentActiveSlider(slider);
    setSliderBack(nested);
    setTimelineInfo(info);
  };

  useEffect(() => {
    setCurrentScreen("Loan Application Details Page", {
      sourceEntityID,
      loanApplicationID: loanID,
    });
    getBankConnectData();
  }, []);

  async function reloadWaitState() {
    if (loanID) {
      const response = await LoanApplicationService.getWaitState({
        token: fetchToken(),
        loanApplicationID: loanID,
        pageName,
      });

      if (response.status) {
        setWaitStateData(response.data);
      }
    }
  }

  const resolveWaitState = async (endpoint) => {
    setWaitLoading(true);
    try {
      const headers = new Headers();
      if (pageName) {
        headers.append("page-header", pageName);
      } else {
        return noHeadersResponse();
      }
      const data = await Get(
        `/masterDashboard/${endpoint}?loanApplicationID=${loanID}`,
        fetchToken(),
        headers
      );
      if (data.status) {
        let status = await pollWaitState();
        if (status === "completed") {
          await reloadLoanDetails();
          await reloadWaitState();
        }
      } else if (data.error) {
        notify({
          title: "Error",
          text: data.error,
        });
      } else {
        notify({
          title: "Error",
          text: "Failed resolve issue. Please Contact FinBox Team",
        });
      }
    } catch (error) {
      notify({
        title: "Error",
        text: "Failed resolve issue. Please Contact FinBox Team",
      });
    }
    setWaitLoading(false);
  };

  const pollWaitState = async () => {
    let count = 0;
    const MAX_COUNT = 20;
    const callWaitState = async (
      resolve: (msg: "timeout" | "completed") => void,
      reject: () => void
    ) => {
      setTimeout(async () => {
        if (count >= MAX_COUNT) {
          return resolve("timeout");
        }
        if (waitStateData?.waitState) {
          await reloadWaitState();
          count = count + 1;
          callWaitState(resolve, reject);
        } else {
          return resolve("completed");
        }
      }, 3000);
    };
    return new Promise(callWaitState);
  };

  async function getBankConnectData() {
    const token = fetchToken();

    const [statusResponse, rangeResponse] = await Promise.all([
      LoanApplicationService.getBcRange({
        sourceEntityID: sourceEntityID!,
        token,
        pageName,
      }),
      LoanApplicationService.getBcStatus({
        userID: loanDetailsData?.userID,
        token,
        pageName,
      }),
    ]);

    if (statusResponse.status) {
      setBcStatus(statusResponse.data);
    }

    if (rangeResponse.status) {
      setBcRange(rangeResponse.data);
    }
  }

  const pollBcStatus = async () => {
    setTimeout(async () => {
      let response = await LoanApplicationService.getBcStatus({
        userID: loanDetailsData?.userID,
        token: fetchToken(),
        pageName,
      });
      if (response.status) {
        setBcStatus(response.data);
      } else {
        setBcStatus(null);
      }
      if (response.data.status === "processing") pollBcStatus();
      else window.location.reload();
    }, 3000);
  };

  const openBankConnect = () => {
    if (bcStatus && bcRange && loanDetailsData?.userID) {
      setShowBankConnect(true);
    }
  };

  async function generateLink() {
    const params: any = {
      userID: loanDetailsData?.userID,
      token: fetchToken(),
      pageName,
    };
    if (loanDetailsData?.programID && loanDetailsData?.programName) {
      params["program"] = loanDetailsData?.programName;
    }
    const response = await CommonService.generateLink(params);
    if (response.status) {
      trackUserAction("resume_journey", {
        loanID,
        userID: loanDetailsData?.userID || "",
        webLink: response?.data?.url || "",
      });
      setIsGenerateLinkModalOpen(true);
      setWebLink(response.data?.url || "");
    } else {
      notify({
        title: "Error",
        text: response.error,
      });
    }
  }

  async function getSignedAgreement() {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }
    const response = await Get<string>(
      `/masterDashboard/getSignedAgreement?loanApplicationID=${loanID}`,
      fetchToken(),
      headers
    );
    if (response.status) {
      trackUserAction("get_signed_agreement", {
        loanApplicationID: loanID,
        link: response.data,
      });
      const link = response.data;
      let a = document.createElement("a");
      document.body.appendChild(a);
      a.style.cssText = "display: none";
      a.href = link;
      a.click();
      document.body.removeChild(a);
    } else {
      notify({
        text: response.error,
        title: "Error",
      });
    }
  }

  const getSoA = async () => {
    try {
      const headers = new Headers();
      if (pageName) {
        headers.append("page-header", pageName);
      } else {
        return noHeadersResponse();
      }
      const response = await Get<{ exportLink: string }>(
        `/masterDashboard/exportLedger?loanApplicationID=${loanID}`,
        fetchToken(),
        headers
      );
      if (response.status) {
        trackUserAction("get_soa", {
          loanApplicationID: loanID,
          link: response.data?.exportLink || "",
        });
        const link = response.data.exportLink;
        downloadLink(link);
      }
    } catch (e) {
      notify({
        text: "Network Error",
        title: "Error",
      });
    }
  };
  async function getUnsignedAgreement() {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }
    headers.append("Content-Type", "text/html");
    const response = await Get<string>(
      `/masterDashboard/getUnsignedAgreement?loanApplicationID=${loanID}`,
      fetchToken(),
      headers
    );
    if (response.status) {
      const newWindow = window.open();
      newWindow?.document.write(response.data);
      trackUserAction(`unsigned_agreement`, {
        loanApplicationID: loanID,
      });
    } else {
      notify({
        text: response.error,
        title: "Error",
      });
    }
  }
  const getAuthLetter = async () => {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }
    const response = await Get<string>(
      `/masterDashboard/getAuthLetter?loanApplicationID=${loanID}`,
      fetchToken(),
      headers
    );

    if (response.status) {
      trackUserAction("get_auth_letter", {
        loanApplicationID: loanID,
        link: response.data || "",
      });
      const link = response.data;
      if (link) {
        downloadLink(link);
      }
    } else {
      notify({
        text: response.error,
        title: "Error",
      });
    }
  };

  async function reloadNotesList() {
    try {
      const response = await _getNotes(loanID, fetchToken(), pageName);
      if (response.status) {
        if (response.data.notes) {
          setNotes(response.data.notes);
        }
      } else {
        notify({
          title: "Error",
          text: response.error,
        });
      }
    } catch (error: any) {
      notify({
        title: "Error",
        text: new Error(error.toString()).message,
      });
    }
  }

  async function onAddNote({ note }: any) {
    try {
      const headers = new Headers();
      if (pageName) {
        headers.append("page-header", pageName);
      } else {
        return noHeadersResponse();
      }
      const response = await Post(
        `/masterDashboard/addNote`,
        fetchToken(),
        {
          note,
          loanApplicationID: loanDetailsData?.loanApplicationID,
        },
        headers
      );

      if (response.status) {
        trackUserAction("add_note", {
          loanApplicationID: loanID,
          note: note || "",
        });
        await reloadNotesList();
      } else {
        notify({
          title: "Error",
          text: response.error,
        });
      }
    } catch (error: any) {
      notify({
        title: "Error",
        text: new Error(error.toString()).message,
      });
    }
  }

  const revokeDeleteRequest = async () => {
    const response = await LoanApplicationService.revokeDeleteRequest({
      userID: loanDetailsData?.userID,
      token: fetchToken(),
      pageName,
    });

    if (response.status) {
      trackUserAction("revoke_delete_request", {
        loanApplicationID: loanID,
        userID: loanDetailsData?.userID,
      });
      reloadLoanDetails();
    } else {
      notify({
        title: "Error",
        text: response.error,
      });
    }
  };

  const reloadLoanDetails = async () => {
    const response = await LoanApplicationService.getLoanDetails({
      loanApplicationID: loanID as string,
      token: fetchToken(),
      pageName,
    });

    if (response.status) {
      setLoanDetailsData(response.data);
      return response;
    } else {
      notify({
        title: "Error",
        text: response.error,
      });
    }
  };

  const reloadWorkflowDetails = async () => {
    const response = await LoanApplicationService.getWorkflows({
      loanApplicationID: loanID as string,
      token: fetchToken(),
      pageName,
    });

    if (response.status) {
      setWorkflowData(response.data);
      return response;
    } else {
      notify({
        title: "Error",
        text: response.error,
      });
    }
  };
  const prependBankConnectItem = (items) => {
    const bankConnectData = {
      name: "Bank Connect",
      key: "bankConnect",
    };

    let value = "";

    switch (bcStatus?.status) {
      case "not_started":
        value = (
          <button
            className="text-indigo-600 text-xs font-medium"
            onClick={openBankConnect}
          >
            Upload Bank Statements
          </button>
        );
        break;

      case "failed":
        value = (
          <button
            className="text-indigo-600 text-xs font-medium"
            onClick={openBankConnect}
          >
            Retry Uploading Bank statements{" "}
            <span className="block">{bcStatus?.failureReason}</span>
          </button>
        );
        break;

      case "processing":
        value = (
          <button className="text-indigo-600 text-xs font-medium">
            Analysing Bank Statements
          </button>
        );
        break;

      default:
        break;
    }
    if (value) {
      bankConnectData.value = value;
    }
    return [bankConnectData, ...items];
  };

  async function approveBusinessKYC() {
    const response = await LoanApplicationService.approveBusinessKYC({
      loanApplicationID: loanID as string,
      token: fetchToken(),
      pageName,
    });

    if (response.status) {
      notify({
        title: "Success",
        text: "Business KYC Approved",
        type: "success",
      });
      navigate(0);
    } else {
      notify({
        title: "Error",
        text: response.error || "Business KYC Approval Failed",
      });
    }
  }

  const isApiStackUser =
    loanDetailsData?.loanDetails?.source !== "API_STACK_USER"
      ? true
      : loanDetailsData?.organizationID !== ORG_IDS.ABFL;

  const isAllowedToApproveKYC =
    ["KYC_PROCESSING", "KYC_REJECTED"].includes(loanDetailsData?.status) &&
    isApiStackUser;
  // This feature was implemented for Khatabook. Since they are no longer a customer, this is commented out
  // const isApproveLoan =
  //   !["DISBURSED", "LOAN_REJECTED", "CLOSED", "CANCELLED"].includes(
  //     loanDetailsData.status
  //   ) && loanDetailsData.status === "KYC_SUCCESS";
  const isRejectLoan =
    !["DISBURSED", "LOAN_REJECTED", "CLOSED", "CANCELLED"].includes(
      loanDetailsData?.status
    ) && isApiStackUser;
  const canCancelApplication =
    ![
      "DISBURSED",
      "LOAN_REJECTED",
      "CLOSED",
      "CANCELLED",
      "SIGN_AGREEMENT",
    ].includes(loanDetailsData?.status) && isApiStackUser;
  const canDownloadAuthLetter =
    ["BANK_ADDED", "SIGN_AGREEMENT", "DISBURSED"].includes(
      loanDetailsData?.status
    ) && isApiStackUser;
  const canDownloadSignAgreement =
    ["SIGN_AGREEMENT", "DISBURSED", "CLOSED"].includes(
      loanDetailsData?.status
    ) && isApiStackUser;
  const canGetSoA =
    ["DISBURSED", "CLOSED"].includes(loanDetailsData?.status) &&
    ["personal_loan", "business_loan"].includes(loanDetailsData?.loanType);
  const canSendSignAgreementToLender = loanDetailsData?.showSignAgreementButton;
  const showApproveBusinessKYC = workflowLoaderData?.workflows?.some(
    (workflow) =>
      workflow.workflowName === "abfl_kyc_wf" &&
      !["approved", "rejected"].includes(workflow.status) &&
      workflow.actions["abfl_approve_business_docs"] === true
  );

  const showUnsignedAgreement =
    ["BANK_ADDED", "SIGN_AGREEMENT", "DISBURSED", "CLOSED"].includes(
      loanDetailsData?.status
    ) && isApiStackUser;

  const getCibilPdfReport = async () => {
    const response = (await LoanApplicationService.getCibilPdfReport({
      userID: loanDetailsData?.userID!,
      token: fetchToken(),
      pageName,
    })) as any;
    if (response?.status) {
      window.open(response.data?.cibilReport, "_blank");
      notify({
        title: "Success",
        type: "success",
        text: "Successfully downloaded CIBIL Report",
      });
    } else {
      notify({
        title: "Error",
        text: response.error,
      });
    }
  };

  const getCIBILReportsExcel = async () => {
    const response = (await LoanApplicationService.getCIBILReportsExcel({
      userID: loanDetailsData?.userID!,
      token: fetchToken(),
      pageName,
    })) as any;
    if (response?.status) {
      notify({
        title: "Success",
        type: "success",
        text: "Successfully downloaded Bureau Report",
      });
      window.open(response.data?.zipUrl, "_blank");
    } else {
      notify({
        title: "Error",
        text: response.error,
      });
    }
  };

  const actionItems = [
    {
      value: (
        <PrivateComponent
          requiredPage={pageName}
          sourceEntityId={sourceEntityID}
          requiredFunctions={[ACTIONS_LIST.SEND_AGREEMENT_TO_LENDER]}
        >
          <ActionButton
            title="Send Agreement to Lender"
            onClick={() => setShowSendSignAgreementModal(true)}
            icon={<DocumentCheckIcon className="h-4 w-4 mr-2 font-bold" />}
          />
        </PrivateComponent>
      ),
      isShow: canSendSignAgreementToLender,
    },
    {
      value: (
        <PrivateComponent
          requiredPage={pageName}
          sourceEntityId={sourceEntityID}
          requiredFunctions={[ACTIONS_LIST.RESUME_JOURNEY]}
        >
          <ActionButton
            title="Resume Journey"
            onClick={async () => {
              await generateLink();
            }}
            icon={<ArrowPathIcon className="h-4 w-4 mr-2 font-bold" />}
          />
        </PrivateComponent>
      ),
      isShow: isApiStackUser,
    },
    {
      value: (
        <PrivateComponent
          requiredPage={pageName}
          sourceEntityId={sourceEntityID}
          requiredFunctions={[ACTIONS_LIST.APPROVE_KYC]}
        >
          <ActionButton
            title="Approve KYC"
            onClick={() => setShowApproveKYCModal(true)}
            icon={<ApproveLoanIcon className="w-4 h-4 mr-2" />}
          />
        </PrivateComponent>
      ),
      isShow: isAllowedToApproveKYC,
    },
    // This feature was implemented for Khatabook. Since they are no longer a customer, this is commented out
    // {
    //   value: (
    //     <PrivateComponent
    //       requiredPage={pageName}
    //       requiredFunctions={[ACTIONS_LIST.APPROVE_LOAN]}
    //     >
    //       <ActionButton onClick={() => {}} title={"Approve Loan"} />
    //     </PrivateComponent>
    //   ),
    //   isShow: isApproveLoan,
    // },
    {
      value: (
        <PrivateComponent
          requiredPage={pageName}
          sourceEntityId={sourceEntityID}
          requiredFunctions={[ACTIONS_LIST.REJECT_LOAN]}
        >
          <ActionButton
            title="Reject Loan"
            onClick={() => setShowDisqualifyLoanModal(true)}
            icon={<RejectLoanIcon className="w-4 h-4 mr-2" />}
          />
        </PrivateComponent>
      ),
      isShow: isRejectLoan,
    },
    {
      value: (
        <PrivateComponent
          requiredPage={pageName}
          sourceEntityId={sourceEntityID}
          requiredFunctions={[ACTIONS_LIST.REVOKE_DELETE_REQUEST]}
        >
          <ActionButton
            onClick={revokeDeleteRequest}
            title="Revoke Delete Request"
            icon={<BackwardIcon className="h-4 w-4 mr-2 font-bold" />}
          />
        </PrivateComponent>
      ),
      isShow: isApiStackUser,
    },
    {
      value: (
        <PrivateComponent
          requiredPage={pageName}
          sourceEntityId={sourceEntityID}
          requiredFunctions={[ACTIONS_LIST.CANCEL_APPLICATION]}
        >
          <ActionButton
            title="Cancel Application"
            onClick={() => setShowCancelLoanApplicationModal(true)}
            icon={<CancelApplicationIcon className="w-4 h-4 mr-2" />}
          />
        </PrivateComponent>
      ),
      isShow: canCancelApplication,
    },
    {
      value: (
        <PrivateComponent
          requiredPage={pageName}
          sourceEntityId={sourceEntityID}
          requiredFunctions={[ACTIONS_LIST.ADD_OFFER_NEGOTIATION]}
        >
          <ActionButton
            title={"Initiate Loan Deviation"}
            onClick={() => {
              currentActiveSliderHandler("INITIATE");
            }}
            isDisabled={!loanTermDeviationStatus?.allowOfferWFInit}
          />
        </PrivateComponent>
      ),
      isShow: loanTermDeviationStatus?.allowOfferWFInit && isApiStackUser,
    },
    {
      value: (
        <PrivateComponent
          requiredPage={pageName!}
          requiredFunctions={[ACTIONS_LIST.APPROVE_BUSINESS_DOCS]}
          sourceEntityId={sourceEntityID}
        >
          <ActionButton
            title={"Approve Business Details"}
            onClick={() => setShowConfirmDialog(true)}
          />
        </PrivateComponent>
      ),
      isShow: showApproveBusinessKYC && isApiStackUser,
    },
  ];

  const docActions = [
    {
      value: (
        <PrivateComponent
          requiredPage={pageName}
          sourceEntityId={sourceEntityID}
          requiredFunctions={[ACTIONS_LIST.DOWNLOAD_AUTHENTICATION_LETTER]}
        >
          <ActionButton
            title="Auth Letter"
            onClick={async () => {
              await getAuthLetter();
            }}
            icon={<ArrowDownTrayIcon className="w-4 h-4 mr-2 text-inherit" />}
          />
        </PrivateComponent>
      ),
      isShow: canDownloadAuthLetter && isApiStackUser,
    },
    {
      value: (
        <PrivateComponent
          requiredPage={pageName}
          sourceEntityId={sourceEntityID}
          requiredFunctions={[ACTIONS_LIST.DOWNLOAD_SIGNED_AGREEMENT]}
        >
          <ActionButton
            title="Signed Agreement"
            onClick={async () => {
              await getSignedAgreement();
            }}
            icon={<ArrowDownTrayIcon className="w-4 h-4 mr-2 text-inherit" />}
          />
        </PrivateComponent>
      ),
      isShow: canDownloadSignAgreement,
    },
    {
      value: (
        <PrivateComponent
          requiredPage={pageName}
          sourceEntityId={sourceEntityID}
          requiredFunctions={[ACTIONS_LIST.DOWNLOAD_STATEMENT_OF_ACCOUNTS]}
        >
          <ActionButton
            title="Statement of Accounts"
            onClick={async () => {
              await getSoA();
            }}
            icon={<ArrowDownTrayIcon className="w-4 h-4 mr-2 text-inherit" />}
          />
        </PrivateComponent>
      ),
      isShow: canGetSoA && isApiStackUser,
    },
    {
      value: (
        <PrivateComponent
          requiredPage={pageName}
          sourceEntityId={sourceEntityID}
          requiredFunctions={[ACTIONS_LIST.DOWNLOAD_UNSIGNED_AGREEMENT]}
        >
          <ActionButton
            title="Unsigned Agreement"
            onClick={async () => {
              await getUnsignedAgreement();
            }}
            icon={<ArrowDownTrayIcon className="w-4 h-4 mr-2 text-inherit" />}
          />
        </PrivateComponent>
      ),
      isShow: showUnsignedAgreement,
    },
    {
      value: (
        <PrivateComponent
          requiredPage={pageName}
          sourceEntityId={sourceEntityID}
          requiredFunctions={[ACTIONS_LIST.DOWNLOAD_CIBIL_PDF_REPORT]}
        >
          <ActionButton
            title="Download CIBIL"
            onClick={getCibilPdfReport}
            icon={<ArrowDownTrayIcon className="w-4 h-4 mr-2 text-inherit" />}
          />
        </PrivateComponent>
      ),
      isShow: true,
    },
    {
      value: (
        <PrivateComponent
          requiredPage={pageName}
          sourceEntityId={sourceEntityID}
          requiredFunctions={[ACTIONS_LIST.DOWNLOAD_CIBIL_EXCEL_REPORT]}
        >
          <ActionButton
            title="Download Bureau Report"
            onClick={getCIBILReportsExcel}
            icon={<ArrowDownTrayIcon className="w-4 h-4 mr-2 text-inherit" />}
          />
        </PrivateComponent>
      ),
      isShow: true,
    },
  ];

  let sidebarItems = loanDetailSidebarFields.map((field) => {
    return {
      name: loanDetailFields?.[field]?.label ?? "",
      value: loanDetails?.[field] || loanDetailsData?.[field] || "-",
      key: field,
    };
  });
  sidebarItems = prependBankConnectItem(sidebarItems);

  const breadcrumbs: Array<BreadCrumb> = [
    { name: "Loan Applications", href: "/loan-applications", current: false },
    {
      name: isMobile
        ? loanDetailsData?.loanApplicationID
        : loanDetailsData?.loanDetails?.name ||
          loanDetailsData?.loanApplicationID,
      href: `/loan-applications/details/${sourceEntityID}/${loanID}`,
      current: false,
    },
  ];

  let tabs = getTabsByProductType(
    "loan_application",
    loanDetailsData?.loanType,
    permissions,
    pageConfig?.[pageName]?.tabs
  );

  // console.log("tabs => ", tabs);

  // Currently condition will return true only for Tata Group
  if (userState?.hideKYC) {
    tabs = tabs.filter((tab) => tab.name !== "KYC");
  }

  return (
    <>
      <div className="min-h-full">
        <PageHeader
          title=""
          breadcrumbs={breadcrumbs}
        />

        {(waitStateData?.waitState || waitStateData?.failedState) && (
          <div className="mt-4">
            <div
              className="rounded-lg w-full p-4 flex flex-wrap md:flex-nowrap items-center justify-between"
              style={{
                background: waitStateData?.waitState ? "#fff3df" : "#ffe2e2",
                border:
                  "1px dashed " + waitStateData?.waitState
                    ? "#cccccc"
                    : "#bd8787",
              }}
            >
              <div className="w-full mb-1 md:mb-0 md:w-1/2">
                {waitStateData?.waitState && (
                  <>
                    <span
                      className="font-semibold text-sm"
                      style={{
                        color: "#df9c00",
                      }}
                    >
                      Application in wait state
                    </span>
                    <br />
                  </>
                )}
                {waitStateData?.reason && (
                  <span
                    className="text-xs font-medium"
                    style={{
                      color: waitStateData?.waitState ? "#a39075" : "#a75d5d",
                    }}
                  >
                    {waitStateData?.reason}
                  </span>
                )}
              </div>
              <div className="w-full md:w-1/2 flex items-center justify-end">
                {waitStateData?.suggestedAction && (
                  <div
                    className="text-xs"
                    style={{
                      color: waitStateData?.waitState ? "#91836e" : "#c54141",
                    }}
                  >
                    {waitLoading
                      ? "Pushing data to lender"
                      : waitStateData?.suggestedAction}
                    .
                    {waitStateData?.suggestedActionType && (
                      <button
                        className="block underline leading-6"
                        onClick={() =>
                          resolveWaitState(waitStateData?.suggestedActionType)
                        }
                      >
                        {waitLoading ? "Loading..." : "Click here to retrigger"}
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {status ? (
          <main className="py-0 md:pt-6 md:pb-3">
            <div className="mx-auto max-w-3xl lg:max-w-full flex items-start justify-between md:space-x-2">
              {/* Sidebar */}
              <DetailsSidebar
                items={sidebarItems}
                actions={actionItems}
                docActions={docActions}
                status={loanDetailsData?.status}
                selfie={loanDetailsData?.selfie}
                config={LOAN_STATUS_CONFIG[loanDetailsData.status]}
                details={{
                  ...loanDetailsData,
                  ...loanDetailsData?.loanDetails,
                }}
              />

              <div className="w-full md:w-[80%]">
                {/* Description list*/}
                <section aria-labelledby="applicant-information-title">
                  <div className="bg-white md:rounded-lg md:border md:border-gray-200">
                    <div className="rounded-bl-none rounded-br-none px-2 md:px-0 hidden md:block">
                      <TabsOne
                        tabs={tabs}
                        hasScroll={true}
                        styles={{
                          tab: "min-w-max px-8",
                        }}
                        baseURL={`/loan-applications/details/${sourceEntityID}/${loanID}`}
                      />
                    </div>
                    <div className="md:border-t md:border-gray-200 px-2 md:px-4 py-2 md:py-5 lg:4 h-600 overflow-auto">
                      <Outlet
                        context={{
                          hasMorePages: false,
                          loanDetails: {
                            ...loanDetails,
                            coApplicantDetails:
                              loanDetailsData?.coApplicantDetails,
                            loanAccountNumber:
                              loanDetailsData?.loanAccountNumber,
                            createdAt: loanDetailsData?.createdAt,
                            updatedAt: loanDetailsData?.updatedAt,
                            cibilScore:
                              loanDetails?.bureauScores.find(
                                (b: any) => b.bureauName === "CIBIL"
                              )?.bureauScore ?? -1,
                            cibilStatus:
                              loanDetails?.bureauScores.find(
                                (b: any) => b.bureauName === "CIBIL"
                              )?.bureauStatus ?? "",
                            loanApplicationNum:
                              loanDetailsData?.loanApplicationNum,
                            sourceEntityName: loanDetailsData?.sourceEntityName,
                            loanType: loanDetailsData?.loanType,
                            userID: loanDetailsData?.userID,
                            status: loanDetailsData?.status,
                            isDirectTxnEnabled:
                              loanDetailsData?.isDirectTxnEnabled,
                            directTransactionFields:
                              loanDetailsData?.directTransactionFields,
                            programName: loanDetailsData?.programName ?? "",
                            reqLoanAmount:
                              loanDetailsData?.reqLoanAmount ?? null,
                            processingFee:
                              loanDetailsData?.processingFee ?? null,
                            utmSource: loanDetailsData?.utmSource ?? "",
                            utmContent: loanDetailsData?.utmContent ?? "",
                            utmMedium: loanDetailsData?.utmMedium ?? "",
                            utmCampaign: loanDetailsData?.utmCampaign ?? "",
                            campaignType: loanDetailsData?.campaignType ?? "",
                          },
                          workflowLoaderData: workflowData,
                          reloadWorkflowDetails,
                          reloadLoanDetails,
                          proposal: {
                            isSliderOpen,
                            setIsSliderOpen,
                            refetchLoanTerm,
                            currentActiveSlider,
                            loanTermDeviationStatus,
                            currentActiveSliderHandler,
                          },
                        }}
                      />
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </main>
        ) : (
          <p className="text-sm py-12">Loan Application not found!</p>
        )}
      </div>

      <div className="fixed bottom-12 right-[5%]">
        <button
          className="px-4 py-2 border-2 border-indigo-600 text-indigo-600 rounded-lg text-sm font-medium bg-white z-10 shadow-lg flex items-center justify-center"
          onClick={() => {
            setIsNotesOpen(true);
            // setIsNotesSlideoutOpen(true);
          }}
        >
          <Bars3BottomRightIcon className="w-4 h-4 mr-2 text-indigo-700" />
          Notes
        </button>
      </div>

      {status ? (
        <>
          <CollapsibleSidebar
            show={isNotesOpen}
            setShow={setIsNotesOpen}
            style={{
              container: "!min-w-[450px] !w-[450px] !w-[25vw] !p-0",
            }}
          >
            <Notes
              notes={notes}
              key={isNotesOpen}
              onSubmit={onAddNote}
              close={() => setIsNotesOpen(false)}
            />
          </CollapsibleSidebar>

          <DialogOne
            title="Resume Journey"
            open={isGenerateLinkModalOpen}
            setOpen={setIsGenerateLinkModalOpen}
            description="Use this link to resume loan journey"
          >
            <GenerateLinkModal
              webLink={webLink}
              setOpen={setIsGenerateLinkModalOpen}
            />
          </DialogOne>

          <DialogOne
            description=""
            open={showApproveKYCModal}
            setOpen={setShowApproveKYCModal}
            title={`Approve KYC for ${loanDetails.name}?`}
          >
            <ApproveKYCModal
              pageName={pageName}
              setOpen={setShowApproveKYCModal}
              reload={() => reloadLoanDetails()}
              loanApplicationID={loanID as string}
            />
          </DialogOne>

          <DialogOne
            description=""
            title="Disqualify User?"
            open={showDisqualifyLoanModal}
            setOpen={setShowDisqualifyLoanModal}
          >
            <DisqualifyLoanApplicationModal
              reload={() => reloadLoanDetails()}
              loanApplicationID={loanID as string}
              setOpen={setShowDisqualifyLoanModal}
            />
          </DialogOne>

          <DialogOne
            description=""
            title="Cancel Application"
            open={showCancelLoanApplicationModal}
            setOpen={setShowCancelLoanApplicationModal}
          >
            <CancelLoanApplicationModal
              pageName={pageName}
              reload={() => reloadLoanDetails()}
              loanApplicationID={loanID as string}
              setOpen={setShowCancelLoanApplicationModal}
            />
          </DialogOne>

          <DialogOne
            open={showSendSignAgreementModal}
            title="Send Sign Agreement to Lender"
            setOpen={setShowSendSignAgreementModal}
          >
            <SendSignAgreementModal
              pageName={pageName}
              reload={() => reloadLoanDetails()}
              loanApplicationID={loanID as string}
              setOpen={setShowSendSignAgreementModal}
            />
          </DialogOne>

          <CollapsibleSidebar
            show={isSliderOpen}
            setShow={setIsSliderOpen}
          >
            <SliderContainer
              pageName={pageName}
              sliderBack={sliderBack}
              isSliderOpen={isSliderOpen}
              timelineInfo={timelineInfo}
              setSliderBack={setSliderBack}
              setIsSliderOpen={setIsSliderOpen}
              loanOfferDetails={loanOfferDetails}
              currentActiveSlider={currentActiveSlider}
              setCurrentSlider={currentActiveSliderHandler}
            />
          </CollapsibleSidebar>

          <DialogOne
            open={showConfirmDialog}
            setOpen={setShowConfirmDialog}
            title={"Confirm"}
          >
            <ConfirmationModal
              cancelButtontext="Cancel"
              onConfirm={() => approveBusinessKYC()}
              onCancel={() => {
                setShowConfirmDialog(false);
              }}
              confirmButtonText={"Confirm"}
            >
              <p className="text-sm mb-4">
                Are you sure you want to approve business details ?
              </p>
            </ConfirmationModal>
          </DialogOne>
        </>
      ) : null}
    </>
  );
}

export default LoanApplicationDetails;
