import React from "react";
import BankAddedLogo from "../../assets/icons/loan_application_page/bank_added.svg";
import BankFailedLogo from "../../assets/icons/loan_application_page/bank_failed.svg";
import BankProcessingLogo from "../../assets/icons/loan_application_page/bank_processing.svg";
import CancelledLogo from "../../assets/icons/common/cancelled.svg";
import ClosedLogo from "../../assets/icons/loan_application_page/closed.svg";
import DisbursedLogo from "../../assets/icons/loan_application_page/disbursed.svg";
import FreshLoanLogo from "../../assets/icons/loan_application_page/fresh_loan.svg";
import KycProcessingLogo from "../../assets/icons/loan_application_page/kyc_processing.svg";
import KycSuccessLogo from "../../assets/icons/loan_application_page/kyc_success.svg";
import KycRejectedLogo from "../../assets/icons/loan_application_page/kyc_rejected.svg";
import LoanApprovedLogo from "../../assets/icons/loan_application_page/loan_approved.svg";
import DetailsSubmittedLogo from "../../assets/icons/loan_application_page/loan_details_submitted.svg";
import LoanRejectedLogo from "../../assets/icons/loan_application_page/loan_rejected.svg";
import SignAgreementLogo from "../../assets/icons/loan_application_page/sign_agreement.svg";

import ApplicationStartedLogo from "../../assets/icons/leads_page/application_started.svg";
import DisqualifiedLogo from "../../assets/icons/leads/disqualified.svg";
import NewUserLogo from "../../assets/icons/leads/new_user.svg";
import ProfileUpdatedLogo from "../../assets/icons/leads/profile_updated.svg";
import QualifiedLogo from "../../assets/icons/leads/qualified.svg";
import TotalLeadsLogo from "../../assets/icons/leads/total_leads.svg";
import UnderReviewLogo from "../../assets/icons/leads_page/under_review.svg";

import PaidLogo from "../../assets/icons/creditline/paid_2.svg";
import UnpaidLogo from "../../assets/icons/creditline/unpaid.svg";
import PartiallyPaidLogo from "../../assets/icons/creditline/partially_paid.svg";
import OverdueLogo from "../../assets/icons/creditline/overdue_2.svg";
import AllStagesLogo from "../../assets/icons/common/all_stages_2.svg";

export const ACCESS_OBJECT = {
  USERS: "dashboardUser" as const,
  APPLICATIONS: "applications" as const,
  KYC: "kyc" as const,
  TXN: "txn" as const,
  INTEGRATION: "integration" as const,
  SETTINGS: "settings" as const,
};

export const ACCESS_PERM = {
  VIEW: "view" as const,
  LIST: "list" as const,
  EDIT: "edit" as const,
  DELETE: "delete" as const,
};

export const GREEN = {
  background: "rgb(229, 249, 239)",
  color: "rgb(20 188 107)",
};

export const RED = {
  background: "rgb(253, 235, 235)",
  color: "rgb(235, 87, 87)",
};

export const GREY = {
  background: "rgb(222, 222, 222)",
  color: "rgb(60, 60, 60)",
};

export const BLUE = {
  background: "rgb(226, 244, 253)",
  color: "rgb(17, 161, 241)",
};

export const LOAN_TABS = [
  {
    name: "Details",
  },
  {
    name: "Activity History",
  },
  {
    name: "Application Form",
  },
  {
    name: "KYC",
  },
  {
    name: "Loan Offer",
  },
  {
    name: "Bank Info",
  },
  {
    name: "Partner Details",
  },
  {
    name: "Payments",
  },
  // {
  //   name: "Bank Statements",
  // },
  {
    name: "Additional Docs",
  },
  {
    name: "Notes",
  },
];

export const CREDITLINE_TABS = [
  {
    name: "Details",
  },
  {
    name: "Activity History",
  },
  {
    name: "CreditLine Details",
  },
  {
    name: "Application Form",
  },
  {
    name: "KYC",
  },
  {
    name: "CreditLine Transactions",
  },
  {
    name: "Bank Info",
  },
  {
    name: "Partner Details",
  },
  {
    name: "Payments",
  },
  // {
  //   name: "Bank Statements",
  // },
  {
    name: "Additional Docs",
  },
  {
    name: "Notes",
  },
];

export const BUSINESS_LOAN_TABS = [{ name: "Business Details" }];

export const DOCUMENT_TYPES = [
  "Bank statement",
  "Passbook photo",
  "Cancelled cheque",
];

export const LEAD_SEARCH_TYPES = [
  {
    name: "Mobile",
    value: "mobile",
  },
  {
    name: "Name",
    value: "name",
  },
  {
    name: "Customer ID",
    value: "customerID",
  },
  {
    name: "PAN",
    value: "pan",
  },
  {
    name: "Email",
    value: "email",
  },
  {
    name: "Partner Code",
    value: "partnerCode",
  },
  {
    name: "Firm Name",
    value: "firmName",
  },
  {
    name: "Source",
    value: "source",
  },
  {
    name: "CRM ID",
    value: "crmID",
  },
];

export const LOAN_APPLICATION_SEARCH_TYPES = [
  {
    name: "Mobile",
    value: "mobile",
  },
  {
    name: "Name",
    value: "name",
  },
  {
    name: "Customer ID",
    value: "customerID",
  },
  {
    name: "Loan Application ID",
    value: "loanApplicationNum",
  },
  {
    name: "Email",
    value: "email",
  },
  {
    name: "Partner Code",
    value: "partnerCode",
  },
];

export const PARTNER_NAME_ERROR_TYPES = {
  validate: "invalid agent name",
  required: "required",
};

export const MOBILE_ERROR_TYPES = {
  validate: "mobile number must have 10 digits",
  required: "required",
};
export const businessTypes = [
  "Proprietorship",
  "Partnership",
  "Private Limited",
  "Public Limited",
];

export enum PartnerSearchType {
  MOBILE = "mobile",
  EMAIL = "email",
  PARTNER_CODE = "partnerCode",
  PARTNER_NAME = "partnerName",
  DEFAULT = "",
}

export const PartnerTypeValueMap = [
  {
    name: "Mobile",
    value: PartnerSearchType.MOBILE,
  },
  {
    name: "Email",
    value: PartnerSearchType.EMAIL,
  },
  {
    name: "Agent Code",
    value: PartnerSearchType.PARTNER_CODE,
  },
  {
    name: "Agent Name",
    value: PartnerSearchType.PARTNER_NAME,
  },
];

export const USER_STAGE_FILTER_COUNTS = {
  total: 0,
  user_created: 0,
  user_token_issued: 0,
  user_profile_updated: 0,
  user_under_review: 0,
  user_qualified: 0,
  user_disqualified: 0,
  user_expired: 0,
};

export const USER_STAGE_FILTERS = {
  total: {
    value: 0,
    label: "All Stages",
    icon: TotalLeadsLogo,
    isSelected: true,
  },
  user_created: {
    value: 0,
    label: "Created",
    color: "#11A1F1",
    bg: "#E2F4FD",
    icon: NewUserLogo,
    theme: BLUE,
    isSelected: false,
  },
  user_token_issued: {
    value: 0,
    label: "Journey Started",
    color: "#11A1F1",
    bg: "#E2F4FD",
    icon: ApplicationStartedLogo,
    theme: BLUE,
    isSelected: false,
  },
  user_profile_updated: {
    value: 0,
    label: "Profile Updated",
    color: "#24ca7a",
    bg: "#e5f9ef",
    icon: ProfileUpdatedLogo,
    theme: BLUE,
    isSelected: false,
  },
  user_under_review: {
    value: 0,
    label: "Under Review",
    color: "#E7B10A",
    bg: "#FBF0B2",
    icon: UnderReviewLogo,
    theme: BLUE,
    isSelected: false,
  },
  user_qualified: {
    value: 0,
    color: "#24ca7a",
    bg: "#e5f9ef",
    label: "User Qualified",
    icon: QualifiedLogo,
    theme: GREEN,
    isSelected: false,
  },
  user_disqualified: {
    value: 0,
    label: "Disqualified",
    color: "#eb5757",
    bg: "#fdebeb",
    icon: DisqualifiedLogo,
    theme: RED,
    isSelected: false,
  },
  user_expired: {
    value: 0,
    label: "Expired",
    color: "#eb5757",
    bg: "#fdebeb",
    icon: DisqualifiedLogo,
    theme: RED,
    isSelected: false,
  },
};

export const OVERDRAFT_STAGE_FILTERS = {
  total: {
    value: 0,
    label: "All Stages",
    icon: AllStagesLogo,
  },
  unpaid: {
    value: 0,
    label: "Unpaid",
    icon: UnpaidLogo,
    theme: RED,
  },
  paid: {
    value: 0,
    label: "Paid",
    icon: PaidLogo,
    theme: GREEN,
  },
  partial: {
    value: 0,
    label: "Partial Paid",
    icon: PartiallyPaidLogo,
    theme: BLUE,
  },
  overdue: {
    value: 0,
    label: "Overdue",
    icon: OverdueLogo,
    theme: RED,
  },
};

// Red - Disqualified, Cancelled, KYC rejected, Rejected, Bank Failed
// rgb(235, 87, 87); Color
// rgb(253, 235, 235); Background

// Green - Approved, Bank Added, Sign Agreement, Disbursed, KYC Success
// rgb(36, 202, 122); Color

// rgb(229, 249, 239); Background
// Blue - Fresh, Details Submitted, Bank Processing,
// rgb(226, 244, 253);Background
// rgb(17, 161, 241); Color (edited)

// 10:58
// Grey - Closed
// rgb(60, 60, 60); Color
// rgb(222, 222, 222); Background (edited)

export const LOAN_STAGE_FILTER_COUNTS = {
  total: 0,
  fresh_loan: 0,
  cancelled: 0,
  loan_details_submitted: 0,
  kyc_rejected: 0,
  kyc_processing: 0,
  kyc_success: 0,
  under_review: 0,
  loan_rejected: 0,
  loan_approved: 0,
  bank_failed: 0,
  bank_processing: 0,
  bank_added: 0,
  sign_agreement: 0,
  disbursed: 0,
  closed: 0,
};

export const LOAN_STAGE_FILTERS = {
  total: {
    value: 0,
    label: "All Stages",
    icon: AllStagesLogo,
    isSelected: true,
  },
  fresh_loan: {
    value: 0,
    label: "Fresh Loan",
    icon: FreshLoanLogo,
    theme: BLUE,
    isSelected: false,
  },
  cancelled: {
    value: 0,
    label: "Cancelled",
    icon: CancelledLogo,
    theme: RED,
    isSelected: false,
  },
  loan_details_submitted: {
    value: 0,
    label: "Details Submitted",
    icon: DetailsSubmittedLogo,
    theme: BLUE,
    isSelected: false,
  },
  kyc_rejected: {
    value: 0,
    label: "KYC Rejected",
    icon: KycRejectedLogo,
    theme: RED,
    isSelected: false,
  },
  kyc_processing: {
    value: 0,
    label: "KYC Processing",
    icon: KycProcessingLogo,
    theme: BLUE,
    isSelected: false,
  },
  kyc_success: {
    value: 0,
    label: "KYC Success",
    icon: KycSuccessLogo,
    theme: GREEN,
    isSelected: false,
  },
  under_review: {
    value: 0,
    label: "Under Review",
    icon: KycSuccessLogo,
    theme: BLUE,
    isSelected: false,
  },
  loan_rejected: {
    value: 0,
    label: "Loan Rejected",
    icon: LoanRejectedLogo,
    theme: RED,
    isSelected: false,
  },
  loan_approved: {
    value: 0,
    label: "Loan Approved",
    icon: LoanApprovedLogo,
    theme: GREEN,
    isSelected: false,
  },
  bank_failed: {
    value: 0,
    label: "Bank Failed",
    icon: BankFailedLogo,
    theme: RED,
    isSelected: false,
  },
  bank_processing: {
    value: 0,
    label: "Bank Processing",
    icon: BankProcessingLogo,
    theme: BLUE,
    isSelected: false,
  },
  bank_added: {
    value: 0,
    label: "Bank Added",
    icon: BankAddedLogo,
    theme: GREEN,
    isSelected: false,
  },
  sign_agreement: {
    value: 0,
    label: "Sign Agreement",
    icon: SignAgreementLogo,
    theme: GREEN,
    isSelected: false,
  },
  disbursed: {
    value: 0,
    label: "Disbursed",
    icon: DisbursedLogo,
    theme: GREEN,
    isSelected: false,
  },
  closed: {
    value: 0,
    label: "Closed",
    icon: ClosedLogo,
    theme: GREY,
    isSelected: false,
  },
  // address_verification_queue: {
  //   value: 0,
  //   label: "Address Verification Queue",
  //   icon: "",
  // },
};

export const PRODUCT_TYPES = [
  {
    label: "Business Loan",
    type: "productType",
    name: "business_loan",
    isChecked: false,
    value: "business_loan",
  },
  {
    label: "Personal Loan",
    type: "productType",
    name: "personal_loan",
    isChecked: false,
    value: "personal_loan",
  },
  {
    label: "Credit Line",
    type: "productType",
    name: "credit_line",
    isChecked: false,
    value: "credit_line",
  },
  {
    label: "Overdraft",
    type: "productType",
    name: "overdraft",
    isChecked: false,
    value: "overdraft",
  },
];

export const GET_SE_LIST_OBJECTS = {
  users: "user" as const,
  applications: "application" as const,
  kyc: "kyc" as const,
  txn: "transaction" as const,
  settings: "settings" as const,
  integration: "integration" as const,
};

export const CONSTITUTION_TYPES = [
  {
    label: "Proprietorship",
    type: "constitution",
    name: "Proprietorship",
    isChecked: false,
    value: "Proprietorship",
  },
  {
    label: "Partnership",
    type: "constitution",
    name: "Partnership",
    isChecked: false,
    value: "Partnership",
  },
  {
    label: "Private Limited",
    type: "constitution",
    name: "Private Limited",
    isChecked: false,
    value: "Private Limited",
  },
  {
    label: "Public Limited",
    type: "constitution",
    name: "Public Limited",
    isChecked: false,
    value: "Public Limited",
  },
];

export const PLATFORMS_LIST = {
  MuthootCL: "ad97435f-af0b-4bed-849e-1b309f943087",
};

export const NO_HEADERS_RESPONSE = {
  data: null,
  error: "No headers set for this action",
  status: false,
};

export const PAGES_LIST = {
  CO_APPLICANT_DETAILS_PAGE: "CoApplicant Details Page",
  LOAN_APPLICATIONS_LISTING_PAGE: "Loan Application Page",
  LOAN_APPLICATION_DETAILS_PAGE: "Loan Application Details Page",
  LOAN_APPLICATION_DETAILS_TAB: "Loan Application Details Tab",
  LOAN_APPLICATION_TIMELINE_TAB: "Loan Application Timeline Tab",
  LOAN_APPLICATION_FORM_TAB: "Loan Application Application Form Tab",
  LOAN_APPLICATION_KYC_TAB: "Loan Application KYC Tab",
  LOAN_APPLICATION_LOAN_OFFER_TAB: "Loan Application Loan Offer Tab",
  LOAN_APPLICATION_CREDITLINE_DETAILS_TAB:
    "Loan Application Creditline Details Tab",
  LOAN_APPLICATION_CREDITLINE_TRANSACTIONS_TAB:
    "Loan Application Creditline Transactions Tab",
  LOAN_APPLICATION_BANK_INFO_TAB: "Loan Application Bank Info Tab",
  LOAN_APPLICATION_PARTNER_INFO_TAB: "Loan Application Partner Details Tab",
  LOAN_APPLICATION_PAYMENTS_TAB: "Loan Application Payments Tab",
  LOAN_APPLICATION_BANK_STATEMENTS_TAB: "Loan Application Bank Statements Tab",
  LOAN_APPLICATION_BUSINESS_DETAILS_TAB:
    "Loan Application Business Details Tab",
  LOAN_APPLICATION_ADDITIONAL_DOCS_TAB:
    "Loan Application Additional Documents Tab",
  LOAN_APPLICATION_NOTES_TAB: "Loan Application Notes Tab",
  LOAN_APPLICATION_CASE_STATUS_TAB: "Loan Application Case Status Tab",
  LOAN_APPLICATION_LOAN_TERM_DEVIATION_TAB:
    "Loan Application Loan Term Deviation Tab",
  LOAN_APPLICATION_WORKFLOW_TIMELINE_TAB:
    "Loan Application Workflow Timeline Tab",

  LEADS_LISTING_PAGE: "Leads Page",
  LEAD_DETAILS_PAGE: "Leads Details Page",
  LEAD_DETAILS_TAB: "Leads Details Tab",
  LEAD_TIMELINE_TAB: "Leads Timeline Tab",
  LEADS_CASE_STATUS_TAB: "Leads Case Status Tab",
  LEAD_PARTNER_DETAILS_TAB: "Leads Partner Details Tab",
  LEAD_BUSINESS_DETAILS_TAB: "Leads Business Details Tab",
  LEAD_BANK_STATEMENTS_TAB: "Leads Bank Statements Tab",

  CREATE_AGENT_PAGE: "Create Agent Page",
  CHOOSE_PARTNER_PAGE: "Choose Partner Page",
  PARTNERS_PAGE: "Partners Page",
  MOBILE_HOME_PAGE: "Mobile Home Page",
  MEMBERS_PAGE: "Members Page",
  ADMIN_PAGE: "Admin Page",
  ADMIN_POLICY_MANAGEMENT_TAB: "Admin Policy Management Tab",
  ADMIN_USER_MANAGEMENT_TAB: "Admin User Management Tab",
  THEME_CONFIG_SETTINGS_TAB: "Integrations Page Themes Tab",
  CREDITLINK_SETTINGS_TAB: "Integrations Page Credit Link Tab",
  API_INTEGRATION_SETTINGS_TAB: "Integrations Page Integrations Tab",
  INTEGRATIONS_PAGE: "Integrations Page",
  OVERDRAFT_LISTING_PAGE: "Overdraft Bills Page",
  ACTIVELINE_LISTING_PAGE: "Creditline Active Lines Page",
  USER_PROFILE_PAGE: "User Profile Page",
  ALL_TRANSACTIONS_LISTING_PAGE: "Creditline All Transactions Page",
  CREATE_NEW_LEAD_PAGE: "Create New Lead Page",
  REPORTS_PAGE: "Reports Page",
  UTILITIES_PAGE: "Utilities Page",
  ANALYTICS_PAGE: "Analytics Page",
} as const;

export const ACTIONS_LIST = {
  APPROVE_KYC: "Approve KYC",
  APPROVE_LOAN: "Approve Loan",
  REJECT_KYC: "Reject KYC",
  REJECT_LOAN: "Reject Loan",
  PUSH_ADDITIONAL_DOCUMENT: "Push Additional Document",
  CATEGORY_DROPDOWN: "Category Dropdown",
  MANUAL_APPROVE_BANK_DETAILS: "Manual Approve Bank Details",
  REJECT_DOCUMENT: "Reject Document",
  CANCEL_APPLICATION: "Cancel Application",
  UPDATE_BANK_ACCOUNT_TYPE: "Update Bank Account Type",
  DOWNLOAD_AUTHENTICATION_LETTER: "Download Authentication Letter",
  DOWNLOAD_SIGNED_AGREEMENT: "Download Signed Agreement",
  DOWNLOAD_UNSIGNED_AGREEMENT: "Download Unsigned Agreement",
  DOWNLOAD_CIBIL_PDF_REPORT: "Download CIBIL PDF report",
  ADD_REFERENCES_FOR_USER: "Add References For User",
  DOWNLOAD_CIBIL_EXCEL_REPORT: "Cibil Reports Excel",
  DOWNLOAD_STATEMENT_OF_ACCOUNTS: "Download Statement of Accounts",
  SEND_AGREEMENT_TO_LENDER: "Send Agreement to Lender",
  REVOKE_DELETE_REQUEST: "Revoke Delete Request",
  RESUME_JOURNEY: "Resume Journey",
  EDIT_NAME: "Edit Name",
  EDIT_FATHERS_NAME: "Edit Fathers Name",
  EDIT_ADDRESS: "Edit Address",
  EDIT_PERMANENT_ADDRESS: "Edit Permanent Address",
  EDIT_DOB: "Edit DOB",
  EDIT_GENDER: "Edit Gender",
  RUN_KYC_CHECKS: "Run KYC Checks",
  APPROVE_BUSINESS_DOCS: "Approve Business Documents",
  INITIATE_NEW_APPLICATION: "Initiate New Application",
  REASSIGN_LEADS: "Reassign Leads",
  SKIP_BANK_CONNECT: "Skip Bank Connect",
  EXPIRE_USER: "Expire User",
  REVERT_DISQUALIFICATION: "Revert Disqualification",
  RETRIGGER_DEVICE_API: "Retrigger Device API",
  SUBMIT_KYC: "Submit KYC",
  RESUBMIT_KYC: "Resubmit KYC",
  CREATE_TRANSACTIONS: "Create Transactions",
  RESOLVE_QUERY: "Resolve Query",
  CANCEL_TRANSACTION: "Cancel Transaction",
  MOVE_TO_PROCESSING: "Move to Processing",
  SPLIT_TRANSACTION: "Split Transaction",
  CHANGE_ACCOUNT_HOLDER_NAME: "Change Account Holder Name",
  GENERATE_EMANDATE_LINK: "Generate E-Mandate Link",
  REFRESH_BANK_STATEMENTS: "Refresh Bank Statements",
  DOWNLOAD_BANK_CONNECT_REPORT: "Download Bank Connect Report",
  ADD_DOCUMENTS: "Add Documents",
  EDIT_BUSINESS_DETAILS: "Edit Business Details",
  EDIT_BUSINESS_ADDRESS: "Edit Business Address",
  DOWNLOAD_GSTIN: "Download GSTIN",
  CREATE_NEW_AGENT: "Create New Agent",
  BULK_CREATE_AGENTS: "Bulk Create Agents",
  BULK_REASSIGN_AGENTS: "Bulk Reassign Agents",
  EXPORT_LEAD_LEVEL_DATA_DUMP: "Export Lead Level Data Dump",
  SALES_DISPOSITION_REPORT: "Sales Disposition Report",
  EXPORT_REJECTION_REASON_DATA_DUMP: "Export Rejection Reason Data Dump",
  EXPORT_TRANSACTION_DATA_DUMP: "Export Transaction Data Dump",
  EXPORT_OD_APPLICATION_DATA_DUMP: "Export OD Application Data Dump",
  EXPORTN_COLLECTION_DATA_DUMP: "Export Collection Data Dump",
  EXPORT_APPLICATION_DATA_DUMP: "Export Application Data Dump",
  EXPORT_USER_DATA_DUMP: "Export User Data Dump",
  ONBOARDING_FUNNEL_REPORT: "Onboarding Funnel Report",
  DISQUALIFICATION_REPORT: "Disqualification Report",
  DOCUMENT_REPORT: "Document Report",
  LOAN_STATUS_REPORT: "Loan Status Report",
  FUNNEL_REPORT: "Funnel Report",
  INSURANCE_REPORT: "Insurance Report",
  EMPLOYMENT_REPORT: "Employment Report",
  CUSTOMER_USER_DATA_DUMP: "Custom User Data Dump",
  ENACH_MANDATE: "E-nach Mandate",
  CREATE_GROUP: "Create Group",
  ADD_GROUP_PERMISSION: "Add Group Permission",
  DELETE_GROUP_PERMISSION: "Delete Group Permission",
  DELETE_GROUP: "Delete Group",
  UPDATE_MASKING_LEVEL: "Update Masking Level",
  CREATE_ROLE: "Create Role",
  UPDATE_ROLE: "Update Role",
  DELETE_ROLE: "Delete Role",
  CREATE_USER: "Create User",
  DELETE_USER: "Delete User",
  ACTIVATE_USER: "Activate User",
  UPLOAD_DASHBOARD_MEDIA: "Upload",
  UPDATE_CREDITLINE_TRANSACTION_DETAILS: "Update Creditline Txn Details",
  UPDATE_CREDITLINE_TRANSACTION_STATUS: "Update Creditline Txn Status",
  UPDATE_KYC_DOC: "Update KYC Document",
  REFRESH_BANK_STATMENTS: "Refresh Bank Statements",
  UPDATE_WEBHOOK_URL: "Update Webhook URL",
  GET_WEBHOOK_URL: "Get Webhook URL",
  DELETE_DOCUMENTS: "Delete Documents",
  DEMOTE_USER_ADMIN_SETTINGS: "Demote User Admin Settings",
  PROMOTE_USER_TO_ADMIN_SETTINGS: "Promote User To Admin Settings",
  CREATE_DIRECT_TRANSACTION: "Create Direct Transaction",
  VALIDATE_GSTIN: "Validate GSTIN",
  VALIDATE_BANK_ACCOUNT: "Validate Bank Account",
  DUMMY_DOWNLOAD_KYC_DOCS: "Download KYC Docs",
  UPDATE_MFA: "Update MFA Status",
  ADD_OFFER_NEGOTIATION: "Add offer negotiation",
  CREATE_EMPLOYEE_OR_ORGANIZATION: "Create Employee / Organization",
  CREATE_COLLABORATOR: "Create Collaborator",
  DELETE_COLLABORATOR: "Delete Collaborator",
  LIST_COLLABORATOR: "List Collaborator",
  EDIT_EMPLOYEE_OR_ORGANIZATION: "Edit Employee / Organization",
  DEACTIVATE_EMPLOYEE_OR_ORGANIZATION: "Deactivate Employee / Organization",
  DELETE_AGENT: "Delete Agent",
  LIST_ORGANIZATION_HIERARCHY: "List Organization Hierarchy",
  GET_AGENT_DETAILS: "Get Agent Details",
  REASSIGN_MANAGER: "Reassign Manager",
  REACTIVATE_AGENT: "Reactivate Agent",
  BULK_REASSIGN_MANAGERS: "Bulk Reassign Managers",
} as const;

// "Create New Agent",
//     "Bulk Create Agents",
//     "Bulk Reassign Agents",
//     "Create Employee / Organization",
//     "Create Collaborator",
//     "Delete Collaborator",
//     "List Collaborator",
//     "Edit Employee / Organization",
//     "Deactivate Employee / Organization",
//     "List Managers",
//     "List Organization Hierarchy",
//     "Get Agent Details",
//     "Reassign Manager",
//     "Reactivate Agent",
//     "Bulk Reassign Managers"
export const SOURCES = {
  IIFLID: "b46205ae-14f3-43ae-bedb-86d4d82df2f8",
  IIFLBLID: "488ca8e9-b821-4502-b4f0-a0c0a6d02fb1",
  FINBOXID: "7ae9ddeb-85d1-4dfa-a8c9-2662a3663c29",
  ABFLID: "c1a55e53-4701-49a1-923c-3cf32a66b5ec",
} as const;
export const SOURCE_ENTITY_IDS = {
  TDLBNPLID: {
    sourceEntityID: "99bf10ac-0247-4d7c-86d7-0d639bbdb6ff",
    programID: "ec075800-d51f-4864-90c5-f1d05a53ce15",
    programName: "DUAL_LIMIT_PAYLATER",
  },
};

export const ORG_IDS = {
  ABFL: "eabdf414-d3f3-4f1a-ad71-f01d59d9c05b",
  MUTHOOT: "9aad7c89-718d-4767-a6b3-f084a1833c47",
  PFL: "db5fcba9-70cb-42b3-aaf1-d0afc0903e79",
};

export const BUSINESS_LOAN = "business_loan";
export const PERSONAL_LOAN = "personal_loan";
export const CREDIT_LINE = "credit_line";
export const OVERDRAFT = "overdraft";

export const PAGE_CONFIG = {
  default: {
    global: {
      productTypes: [PERSONAL_LOAN, BUSINESS_LOAN, CREDIT_LINE, OVERDRAFT],
      navbarProducts: {
        LOAN_APPLICATION: {
          name: "Loan Applications",
        },
      },
    },
    [PAGES_LIST.LEAD_DETAILS_TAB]: {
      hideKeys: [
        "campaignType", // abfl specific field
        "utmSource", // abfl specific field
        "utmContent", // abfl specific field
        "utmMedium", // abfl specific field
        "utmCampaign", // abfl specific field
        "loanAccountNumber", // pfl specific field
        "radiusFlagResult", // pfl specific field
        "radiusValueKms", // pfl specific field
        "distanceBlocking", // pfl specific field
      ],
    },
    [PAGES_LIST.LOAN_APPLICATION_DETAILS_PAGE]: {
      tabs: {
        LOAN_TABS,
        CREDITLINE_TABS,
        BUSINESS_LOAN_TABS: [...LOAN_TABS, ...BUSINESS_LOAN_TABS],
      },
      hideActions: "default", // check the visibility functions in the respective modules
    },
    [PAGES_LIST.LOAN_APPLICATION_DETAILS_TAB]: {
      hideKeys: [
        "campaignType", // abfl specific field
        "utmSource", // abfl specific field
        "utmContent", // abfl specific field
        "utmMedium", // abfl specific field
        "utmCampaign", // abfl specific field
        "loanAccountNumber", // pfl specific field
        "radiusFlagResult", // pfl specific field
        "radiusValueKms", // pfl specific field
        "distanceBlocking", // pfl specific field
      ],
    },
    [PAGES_LIST.LOAN_APPLICATION_BUSINESS_DETAILS_TAB]: {
      hideKeys: ["businessNature"],
    },
  },
  [ORG_IDS.MUTHOOT]: {
    global: {
      productTypes: [PERSONAL_LOAN, BUSINESS_LOAN, CREDIT_LINE, OVERDRAFT],
      navbarProducts: {
        LOAN_APPLICATION: {
          name: "Loan Applications",
        },
      },
      requiredBusinessDetailsFields: {
        businessPAN: false,
        constitution: false,
      },
    },
    [PAGES_LIST.LEAD_DETAILS_TAB]: {
      hideKeys: [
        "campaignType", // abfl specific field
        "utmSource", // abfl specific field
        "utmContent", // abfl specific field
        "utmMedium", // abfl specific field
        "utmCampaign", // abfl specific field
        "loanAccountNumber", // pfl specific field
        "radiusFlagResult", // pfl specific field
        "radiusValueKms", // pfl specific field
        "distanceBlocking", // pfl specific field
      ],
    },
    [PAGES_LIST.LOAN_APPLICATION_DETAILS_PAGE]: {
      tabs: {
        LOAN_TABS,
        CREDITLINE_TABS,
        BUSINESS_LOAN_TABS: [...LOAN_TABS, ...BUSINESS_LOAN_TABS],
      },
      hideActions: "default", // check the visibility functions in the respective modules
    },
    [PAGES_LIST.LOAN_APPLICATION_DETAILS_TAB]: {
      hideKeys: [
        "campaignType", // abfl specific field
        "utmSource", // abfl specific field
        "utmContent", // abfl specific field
        "utmMedium", // abfl specific field
        "utmCampaign", // abfl specific field
        "loanAccountNumber", // pfl specific field
        "radiusFlagResult", // pfl specific field
        "radiusValueKms", // pfl specific field
        "distanceBlocking", // pfl specific field
      ],
    },
    [PAGES_LIST.LOAN_APPLICATION_BUSINESS_DETAILS_TAB]: {
      hideKeys: ["businessNature"],
    },
  },
  [ORG_IDS.ABFL]: {
    global: {
      productTypes: [BUSINESS_LOAN],
      navbarProducts: {
        LOAN_APPLICATION: {
          name: "Working Capital Term Loan",
        },
      },
      requiredBusinessDetailsFields: {
        businessPAN: false,
        constitution: false,
      },
    },
    [PAGES_LIST.LEAD_DETAILS_TAB]: {
      hideKeys: [
        "cibil",
        "cibilStatus",
        "experian",
        "experianStatus",
        "fisScore",
      ],
      showKeys: [
        "campaignType", // abfl specific field
        "utmSource", // abfl specific field
        "utmContent", // abfl specific field
        "utmMedium", // abfl specific field
        "utmCampaign", // abfl specific field
      ],
    },
    [PAGES_LIST.LOAN_APPLICATIONS_LISTING_PAGE]: {
      filter: {
        taskStatus: true,
      },
    },
    [PAGES_LIST.LOAN_APPLICATION_DETAILS_TAB]: {
      hideKeys: ["cibil", "cibilStatus", "experian", "experianStatus", "fis"],
      showKeys: [
        "campaignType", // abfl specific field
        "utmSource", // abfl specific field
        "utmContent", // abfl specific field
        "utmMedium", // abfl specific field
        "utmCampaign", // abfl specific field
      ],
    },
    [PAGES_LIST.LOAN_APPLICATION_DETAILS_PAGE]: {
      tabs: {
        LOAN_TABS: [
          {
            name: "Details",
          },
          {
            name: "Application Form",
          },
          {
            name: "Underwriting",
          },
          {
            name: "Activity History",
          },
          {
            name: "Co-Applicants",
          },
          {
            name: "Loan Offer",
          },
          {
            name: "KYC",
          },
          {
            name: "Additional Docs",
          },
          {
            name: "Workflow Timeline",
          },
          {
            name: "Manual Credit Review",
          },
          {
            name: "Loan Term Deviation",
          },
          {
            name: "Bank Info",
          },
          {
            name: "Partner Details",
          },
          {
            name: "Payments",
          },
          // {
          //   name: "Bank Statements",
          // },
          {
            name: "Notes",
          },
        ],
        CREDITLINE_TABS,
        BUSINESS_LOAN_TABS: [
          {
            name: "Details",
          },
          {
            name: "Application Form",
          },
          {
            name: "Underwriting",
          },
          {
            name: "Co-Applicants",
          },
          {
            name: "Business Details",
          },
          {
            name: "Activity History",
          },
          {
            name: "Loan Offer",
          },
          {
            name: "KYC",
          },
          {
            name: "Additional Docs",
          },
          {
            name: "Workflow Timeline",
          },
          {
            name: "Manual Credit Review",
          },
          {
            name: "Loan Term Deviation",
          },
          {
            name: "Bank Info",
          },
          {
            name: "Partner Details",
          },
          {
            name: "Payments",
          },
          // {
          //   name: "Bank Statements",
          // },
          {
            name: "Notes",
          },
        ],
      },
      hideActions: "abfl", // check the visibility functions in the respective modules
    },
    [PAGES_LIST.LEAD_BUSINESS_DETAILS_TAB]: {
      hideKeys: ["businessPAN", "last12MonthsSale", "businessCategory"],
      hideFormFields: ["last12MonthsSale"],
    },
    [PAGES_LIST.LOAN_APPLICATION_BUSINESS_DETAILS_TAB]: {
      hideKeys: ["businessPAN", "last12MonthsSale", "businessCategory"],
      hideFormFields: ["last12MonthsSale"],
    },
    [PAGES_LIST.LOAN_APPLICATION_FORM_TAB]: {
      hideKeys: [
        "maritalStatus",
        "educationLevel",
        "fathersName",
        "permanentAddress",
        "mothersName",
        "houseOwnership",
        "registeredBusinessAddress",
        "loanPurpose",
      ],
    },
    [PAGES_LIST.LOAN_APPLICATION_LOAN_OFFER_TAB]: {
      disbursalAmountCalculationType: "custom",
    },
  },
  [ORG_IDS.PFL]: {
    global: {
      productTypes: [PERSONAL_LOAN, BUSINESS_LOAN, CREDIT_LINE, OVERDRAFT],
      navbarProducts: {
        LOAN_APPLICATION: {
          name: "Loan Applications",
        },
      },
    },
    [PAGES_LIST.LEAD_DETAILS_TAB]: {
      showKeys: [
        "radiusFlagResult", // pfl specific field
        "radiusValueKms", // pfl specific field
        "distanceBlocking", // pfl specific field
      ],
    },
    [PAGES_LIST.LOAN_APPLICATION_DETAILS_TAB]: {
      showKeys: [
        "loanAccountNumber", // pfl specific field
        "radiusFlagResult", // pfl specific field
        "radiusValueKms", // pfl specific field
        "distanceBlocking", // pfl specific field
      ],
    },
    [PAGES_LIST.LOAN_APPLICATION_DETAILS_PAGE]: {
      tabs: {
        LOAN_TABS,
        CREDITLINE_TABS,
        BUSINESS_LOAN_TABS: [...LOAN_TABS, ...BUSINESS_LOAN_TABS],
      },
      hideActions: "default", // check the visibility functions in the respective modules
    },
    [PAGES_LIST.LOAN_APPLICATION_BUSINESS_DETAILS_TAB]: {
      hideKeys: ["businessNature"],
    },
  },
};
export const MFA_STATUS = {
  DISABLED: 0,
  ENABLED: 1,
  AUTHENTICATED: 2,
  EMAIL_OTP_AUTHENTICATED: 3,
  MOBILE_OTP_AUTHENTICATED: 4,
} as const;

export const MFA_STATUS_LABELS = {
  0: "Disabled",
  1: "Google MFA Enabled",
  2: "Google MFA Authenticated",
  3: "Email OTP Authenticated",
  4: "Mobile OTP Authenticated",
} as const;
