import { objectKeys } from "../../shared/utils/DateUtil";

export const leadDetailsFields = {
  sourceEntityName: {
    label: "Partner Name",
    allowCopy: false,
    category: "additional-info",
  },
  name: {
    label: "Name",
    allowCopy: false,
    category: "general",
  },
  customerID: {
    label: "Customer ID",
    allowCopy: false,
    category: "general",
  },
  crmID: {
    label: "CRM ID",
    allowCopy: false,
    category: "general",
    isExcludeEmpty: true,
  },
  loanApplicationIDs: {
    label: "Loan ID",
    allowCopy: false,
    category: "general",
  },
  pan: {
    label: "PAN",
    allowCopy: false,
    category: "general",
  },
  gender: {
    label: "Gender",
    allowCopy: false,
    category: "personal",
  },
  dob: {
    label: "DOB",
    allowCopy: false,
    category: "personal",
  },
  mobile: {
    label: "Phone",
    allowCopy: false,
    category: "communication",
  },
  email: {
    label: "Email",
    allowCopy: false,
    category: "communication",
  },
  createdAt: {
    label: "Date Created",
    allowCopy: false,
    category: "general",
  },
  source: {
    label: "Source",
    allowCopy: false,
    category: "additional-info",
  },
  agentCode: {
    label: "Agent Code",
    allowCopy: false,
    category: "additional-info",
  },
  dsaName: {
    label: "Agent Name",
    allowCopy: false,
    category: "additional-info",
  },
  dsaEmail: {
    label: "Agent Email",
    allowCopy: false,
    category: "additional-info",
  },
  dsaMobile: {
    label: "Agent Mobile",
    allowCopy: false,
    category: "additional-info",
  },
  maritalStatus: {
    label: "Marital Status",
    allowCopy: false,
    category: "personal",
  },
  educationLevel: {
    label: "Education",
    allowCopy: false,
    category: "additional-info",
  },
  fathersName: {
    label: "Father's Name",
    allowCopy: false,
    category: "personal",
  },
  loanPurpose: {
    label: "Loan Purpose",
    allowCopy: false,
    category: "general",
  },
  address: {
    label: "Address",
    allowCopy: false,
    category: "personal",
  },
  residenceType: {
    label: "Residence Type",
    allowCopy: false,
    category: "additional-info",
  },
  cibil: {
    label: "CIBIL",
    allowCopy: false,
    category: "additional-info",
  },
  cibilStatus: {
    label: "CIBIL Status",
    allowCopy: false,
    category: "additional-info",
  },
  cibilHardPull: {
    allowCopy: false,
    label: "Hard Pull CIBIL Score",
    category: "additional-info",
  },
  experian: {
    label: "Experian",
    allowCopy: false,
    category: "additional-info",
  },
  experianStatus: {
    label: "Experian Status",
    allowCopy: false,
    category: "additional-info",
  },
  fisScore: {
    label: "FIS Score",
    allowCopy: false,
    category: "additional-info",
  },
  radiusFlagResult: {
    label: "Radius Flag Result",
    allowCopy: false,
    category: "additional-info",
  },
  radiusValueKms: {
    label: "Radius Value in KMs",
    allowCopy: false,
    category: "additional-info",
  },
  distanceBlocking: {
    label: "Distance Blocking",
    allowCopy: false,
    category: "additional-info",
  },
  reqLoanAmount: {
    label: "Requested Loan Amount",
    allowCopy: false,
    category: "general",
  },
  campaignType: {
    label: "Campaign Type",
    allowCopy: false,
    category: "additional-info",
  },
  utmSource: {
    label: "UTM Source",
    allowCopy: false,
    category: "additional-info",
  },
  utmContent: {
    label: "UTM Content",
    allowCopy: false,
    category: "additional-info",
  },
  utmMedium: {
    label: "UTM Medium",
    allowCopy: false,
    category: "additional-info",
  },
  utmCampaign: {
    label: "UTM Campaign",
    allowCopy: false,
    category: "additional-info",
  },
};

const leadDetailsSidebarFieldNames = [
  "loanApplicationNum",
  "loanType",
  "sourceEntityName",
  "mobile",
  "customerID",
  "gender",
  // "agentCode",
  // "experian",
  // "experianStatus",
  // "cibil",
  // "cibilStatus",
  // "fis",
  // "fisStatus",
  // "dsaName",
  // "dsaMobile",
  // "dsaEmail",
];

export const leadDetailSidebarFields = objectKeys(leadDetailsFields).filter(
  (key) => {
    return leadDetailsSidebarFieldNames.includes(key);
  }
);
