import classNames from "classnames";

import Title from "./Title";
import { DeviationProps, LoanDeviation } from "./interface";
import { getFormattedDate } from "../../shared/utils/DateUtil";
import { getFormattedCurrency } from "../../shared/utils/Global";
import { useEffect, useState } from "react";
import { Get } from "../../shared/services/Fetch";
import GetAuthContext from "../../shared/context/AuthContext";
import { WorkflowLog } from "../WorkflowTimeline/interface";
import { notify } from "../../shared/context/AlertContext";

const TEXT_COLOR = {
  cancelled: "text-[#B42318]",
  rejected: "text-[#B42318]",
  accepted: "text-[#05603A]",
  processing: "text-[#3290FE]",
} as const;

function transformTimelineResponse(wfTimeline: WorkflowLog[]): LoanDeviation[] {
  return wfTimeline.filter((wf) => !wf.action.startsWith('Email'))
    .map((wf) => wf.additionalParams?.params as LoanDeviation);
}

export default function Timeline(props: DeviationProps) {
  const [timelineInfo, setTimelineInfo] = useState<LoanDeviation[]>([]);
  const { fetchToken } = GetAuthContext();
  const token = fetchToken();
  
  const GetWorkflowTimeline = async (requestID: string) => {
    const headers = new Headers();
    headers.append("page-header", props.pageName!);

    let response: any = await Get(
        `/masterDashboard/getWorkflowTimeline?requestID=${props?.timelineInfo[0].requestID}`,
        token,
        headers
    );

    if(!response.status) {
        notify({
          title: "Error",
          text: response.error,
        });
        return
    }
    setTimelineInfo(transformTimelineResponse(response.data?.steps as WorkflowLog[]))
}

  useEffect(() => {
    GetWorkflowTimeline(props.selectedRequest!);
  }, [])

  return (
    <>
      <div className="sticky top-[-40px] bg-white">
        <Title
          back={props?.sliderBack}
          title="Loan Term Timeline"
          close={() => props?.setIsSliderOpen(false)}
          backAction={() =>
            props?.setCurrentSlider("CHANGE", false, props?.timelineInfo)
          }
        />
        <div className="mt-6 text-sm font-medium text-[#4C4C4D] pb-5 border-b">
          <div className="flex justify-between items-center mb-1">
            <div>Loan Amount</div>
            <div>
              {getFormattedCurrency(parseFloat(timelineInfo[0]?.loanAmount?.previous)) || "-"}
            </div>
          </div>
          <div className="flex justify-between items-center mb-1">
            <div>Tenure</div>
            <div>{timelineInfo[0]?.tenure?.previous || "-"} Months</div>
          </div>
          <div className="flex justify-between items-center mb-1">
            <div>Rate of Interest %</div>
            <div>{timelineInfo[0]?.interestRate?.previous}%</div>
          </div>
          <div className="flex justify-between items-center mb-1">
            <div>Processing Fee</div>
            <div>{timelineInfo[0]?.processingFee?.previous}</div>
          </div>
          <div className="flex justify-between items-center mb-1">
            <div>Loan Offer Date</div>
            <div>{getFormattedDate(timelineInfo[0]?.createdAt)}</div>
          </div>
        </div>
      </div>
      {timelineInfo?.map((info: any, index: number) => (
        <div className="mt-5 pb-5 border-b" key={index}>
          <div className="flex justify-between items-center mb-2 text-xs">
            <div className="text-[#626262] font-medium">Raised by</div>
            <div className="text-[#8D8D8E] font-normal">{info?.actionBy}</div>
          </div>
          <div className="flex justify-between items-center mb-2 text-xs">
            <div className="text-[#626262] font-medium">Status</div>
            <div
              className={classNames(
                "font-medium capitalize",
                TEXT_COLOR[info?.status] ?? "text-[#3290FE]"
              )}
            >
              {info?.status}
            </div>
          </div>
          <div className="flex justify-between items-center text-xs">
            <div className="text-[#626262] font-medium">Updated by</div>
            <div className="text-[#8D8D8E] font-normal">
              {getFormattedDate(info?.createdAt) ?? "-"}
            </div>
          </div>
          <div className="flex justify-between items-center text-sm mt-3">
            <div className="text-[#777878] font-normal">Requested Tenure</div>
            <div className="text-[#4C4C4D] font-medium">
              {info?.tenure?.proposed || "-"}{" "}
              {info?.tenure?.proposed ? "Months" : ""}
            </div>
          </div>
          <div className="flex justify-between items-center text-sm mt-3">
            <div className="text-[#777878] font-normal">
              Requested Rate of Interest
            </div>
            <div className="text-[#4C4C4D] font-medium">
              {info?.interestRate?.proposed || "-"}
              {info?.interestRate?.proposed ? "%" : ""}
            </div>
          </div>
          <div className="flex justify-between items-center text-sm mt-3">
            <div className="text-[#777878] font-normal">Processing Fee</div>
            <div className="text-[#4C4C4D] font-medium">
              {getFormattedCurrency(info?.processingFee?.proposed) || "-"}
            </div>
          </div>
          {/* comment */}
          <div className="flex text-xs mt-3 flex-col">
            <div className="text-[#626262] font-medium">
              Reason for Deviation{" "}
            </div>
            <div className="text-[#777878] font-normal mt-1">
              {info?.comments ?? "-"}
            </div>
          </div>
        </div>
      ))}
    </>
  );
}
